/* @charset "utf-8"; */
/* CSS Document */

/* FUNCTION */

=setBorderRadius($intRadius)
	-ms-border-radius: $intRadius
	-moz-border-radius: $intRadius
	-o-border-radius: $intRadius
	-webkit-border-radius: $intRadius
	border-radius: $intRadius

=setBorderRadiusTop($intRadius)
	-ms-border-top-left-radius: $intRadius
	-moz-border-top-left-radius: $intRadius
	-webkit-border-top-left-radius: $intRadius
	border-top-left-radius: $intRadius
	-ms-border-top-right-radius: $intRadius
	-moz-border-top-right-radius: $intRadius
	-webkit-border-top-right-radius: $intRadius
	border-top-right-radius: $intRadius

=setBorderRadiusTopLeft($intRadius)
	-ms-border-top-left-radius: $intRadius
	-moz-border-top-left-radius: $intRadius
	-webkit-border-top-left-radius: $intRadius
	border-top-left-radius: $intRadius

=setBorderRadiusTopRight($intRadius)
	-ms-border-top-right-radius: $intRadius
	-moz-border-top-right-radius: $intRadius
	-webkit-border-top-right-radius: $intRadius
	border-top-right-radius: $intRadius

=setBorderRadiusRight($intRadius)
	-ms-border-top-right-radius: $intRadius
	-moz-border-top-right-radius: $intRadius
	-webkit-border-top-right-radius: $intRadius
	border-top-right-radius: $intRadius
	-ms-border-bottom-right-radius: $intRadius
	-moz-border-bottom-right-radius: $intRadius
	-webkit-border-bottom-right-radius: $intRadius
	border-bottom-right-radius: $intRadius

=setBorderRadiusLeft($intRadius)
	-ms-border-top-left-radius: $intRadius
	-moz-border-top-left-radius: $intRadius
	-webkit-border-top-left-radius: $intRadius
	border-top-left-radius: $intRadius
	-ms-border-bottom-left-radius: $intRadius
	-moz-border-bottom-left-radius: $intRadius
	-webkit-border-bottom-left-radius: $intRadius
	border-bottom-left-radius: $intRadius

=setBorderRadiusBottom($intRadius)
	-ms-border-bottom-left-radius: $intRadius
	-moz-border-bottom-left-radius: $intRadius
	-webkit-border-bottom-left-radius: $intRadius
	border-bottom-left-radius: $intRadius
	-ms-border-bottom-right-radius: $intRadius
	-moz-border-bottom-right-radius: $intRadius
	-webkit-border-bottom-right-radius: $intRadius
	border-bottom-right-radius: $intRadius

=setBorderRadiusBottomLeft($intRadius)
	-ms-border-bottom-left-radius: $intRadius
	-moz-border-bottom-left-radius: $intRadius
	-webkit-border-bottom-left-radius: $intRadius
	border-bottom-left-radius: $intRadius

=setBorderRadiusBottomRight($intRadius)
	-ms-border-bottom-right-radius: $intRadius
	-moz-border-bottom-right-radius: $intRadius
	-webkit-border-bottom-right-radius: $intRadius
	border-bottom-right-radius: $intRadius

=perspective($value)
	-ms-perspective: $value
	-moz-perspective: $value
	-o-perspective: $value
	-webkit-perspective: $value
	perspective: $value

=backfaceVisibility($state)
	-ms-backface-visibility: ($state)
	-moz-backface-visibility: ($state)
	-o-backface-visibility: ($state)
	-webkit-backface-visibility: ($state)
	backface-visibility: ($state)

=boxShadow($offsetX, $offsetY, $blurr, $spread, $color)
	-ms-box-shadow: $offsetX $offsetY $blurr $spread $color
	-o-box-shadow: $offsetX $offsetY $blurr $spread $color
	-moz-box-shadow: $offsetX $offsetY $blurr $spread $color
	-webkit-box-shadow: $offsetX $offsetY $blurr $spread $color
	box-shadow: $offsetX $offsetY $blurr $spread $color

=setAppearance($state)
	-ms-appearance: $state
	-moz-appearance: $state
	-o-appearance: $state
	-webkit-appearance: $state
	appearance: $state

=setBoxSizing($state)
	-ms-box-sizing: $state
	-moz-box-sizing: $state
	-o-box-sizing: $state
	-webkit-box-sizing: $state
	box-sizing: $state

=setScrollbarBackgroundColor($stringColor)
	::-ms-scrollbar
		background-color: $stringColor
	::-moz-scrollbar
		background-color: $stringColor
	::-o-scrollbar
		background-color: $stringColor
	::-webkit-scrollbar
		background-color: $stringColor

=setScrollbarThumbColor($stringColor)
	::-ms-scrollbar-thumb
		background-color: $stringColor
	::-moz-scrollbar-thumb
		background-color: $stringColor
	::-o-scrollbar-thumb
		background-color: $stringColor
	::-webkit-scrollbar-thumb
		background-color: $stringColor

=setScrolllbarWidthHeight($stringWidth, $stringHeight)
	::-ms-scrollbar
		height: $stringHeight
		width: $stringWidth
	::-moz-scrollbar
		height: $stringHeight
		width: $stringWidth
	::-o-scrollbar
		height: $stringHeight
		width: $stringWidth
	::-webkit-scrollbar
		height: $stringHeight
		width: $stringWidth

// TRANSITION AND COLOR - START

=setTransition($stringProperty, $intDuration, $stringMovement)
	-ms-transition: (#{$intDuration + "s"}) ($stringMovement)
	-moz-transition: (#{$intDuration + "s"}) ($stringMovement)
	-o-transition: (#{$intDuration + "s"}) ($stringMovement)
	-webkit-transition: (#{$intDuration + "s"}) ($stringMovement)
	transition: (#{$intDuration + "s"}) ($stringMovement)
	-ms-transition-property: $stringProperty
	-moz-transition-property: $stringProperty
	-o-transition-property: $stringProperty
	-webkit-transition-property: $stringProperty
	transition-property: $stringProperty

=generateBackgroundGradientTopBottom($gradient_from_color, $gradient_fromR_color, $gradient_fromG_color, $gradient_fromB_color, $gradient_to_color, $gradient_toR_color, $gradient_toG_color, $gradient_toB_color)
	/* IE 10 */
	background: -ms-linear-gradient(bottom, $gradient_from_color, $gradient_to_color)
	/* Firefox 3.6+ */
	background: -moz-linear-gradient(bottom, $gradient_from_color, $gradient_to_color)
	/* Opera 11.10+ */
	background: -o-linear-gradient(bottom, $gradient_from_color, $gradient_to_color)
	/* Safari 4-5, Chrome 1-9 */
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($gradient_from_color), to($gradient_to_color))
	/* Safari 5.1, Chrome 10+ */
	background: -webkit-linear-gradient(bottom, $gradient_from_color, $gradient_to_color)
	/* Android Built In Browser Below 4.0, Chrome, Safari 4+  */
	// background:-webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(100%, $gradient_from_color), color-stop(0%, $gradient_to_color))
	// background:-webkit-gradient(linear, left top, left bottom, color-stop(100%, $gradient_from_color), color-stop(0%, $gradient_to_color))
	/* Android 2.2, 2.3, 3.0 */
	background: -webkit-linear-gradient(90deg,rgb($gradient_fromR_color, $gradient_fromG_color, $gradient_fromB_color),rgb($gradient_toR_color, $gradient_toG_color, $gradient_toB_color))
	background: linear-gradient(0deg,rgb($gradient_fromR_color, $gradient_fromG_color, $gradient_fromB_color),rgb($gradient_toR_color, $gradient_toG_color, $gradient_toB_color))
	/* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background: -webkit-linear-gradient(bottom, $gradient_from_color, $gradient_to_color)
	/* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
	background: linear-gradient(to top, $gradient_from_color, $gradient_to_color)
	-pie-background: linear-gradient($gradient_from_color, $gradient_to_color)

=generateBackgroundGradientLeftRight($gradient_from_color, $gradient_fromR_color, $gradient_fromG_color, $gradient_fromB_color, $gradient_to_color, $gradient_toR_color, $gradient_toG_color, $gradient_toB_color)
	/* IE 10 */
	background: -ms-linear-gradient(right, $gradient_from_color, $gradient_to_color)
	/* Firefox 3.6+ */
	background: -moz-linear-gradient(right, $gradient_from_color, $gradient_to_color)
	/* Opera 11.10+ */
	background: -o-linear-gradient(right, $gradient_from_color, $gradient_to_color)
	/* Safari 4-5, Chrome 1-9 */
	background: -webkit-gradient(linear, 0% 0%, 0% 100%, from($gradient_from_color), to($gradient_to_color))
	/* Safari 5.1, Chrome 10+ */
	background: -webkit-linear-gradient(right, $gradient_from_color, $gradient_to_color)
	/* Android Built In Browser Below 4.0, Chrome, Safari 4+  */
	// background:-webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(100%, $gradient_from_color), color-stop(0%, $gradient_to_color))
	// background:-webkit-gradient(linear, left top, left bottom, color-stop(100%, $gradient_from_color), color-stop(0%, $gradient_to_color))
	/* Android 2.2, 2.3, 3.0 */
	background: -webkit-linear-gradient(0deg,rgb($gradient_fromR_color, $gradient_fromG_color, $gradient_fromB_color),rgb($gradient_toR_color, $gradient_toG_color, $gradient_toB_color))
	background: linear-gradient(0deg,rgb($gradient_fromR_color, $gradient_fromG_color, $gradient_fromB_color),rgb($gradient_toR_color, $gradient_toG_color, $gradient_toB_color))
	/* Chrome 10-25, iOS 5+, Safari 5.1+ */
	background: -webkit-linear-gradient(right, $gradient_from_color, $gradient_to_color)
	/* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
	background: linear-gradient(to right, $gradient_from_color, $gradient_to_color)
	-pie-background: linear-gradient($gradient_from_color, $gradient_to_color)

=backgroundFilter($filterName, $filterValue)
	-ms-filter: $filterName + unquote('(#{$filterValue})')
	-moz-filter: $filterName + unquote('(#{$filterValue})')
	-o-filter: $filterName + unquote('(#{$filterValue})')
	-webkit-filter: $filterName + unquote('(#{$filterValue})')
	filter: $filterName + unquote(#{$filterValue})

=setMask($value)
	-ms-mask: $value
	-moz-mask: $value
	-o-mask: $value
	-webkit-mask: $value
	mask: $value

=setOpacity($filterValue)
	opacity: $filterValue
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity = $filterValue)"
	filter: alpha(opacity = $filterValue)

// TRANSITION AND COLOR - END

// TRANSFORM - START

=setRotateX($intRotateX)
	-ms-transform: rotateX(#{$intRotateX + "deg"})
	-moz-transform: rotateX(#{$intRotateX + "deg"})
	-o-transform: rotateX(#{$intRotateX + "deg"})
	-webkit-transform: rotateX(#{$intRotateX + "deg"})
	transform: rotateX(#{$intRotateX + "deg"})

=setRotateY($intRotateY)
	-ms-transform: rotateY(#{$intRotateY + "deg"})
	-moz-transform: rotateY(#{$intRotateY + "deg"})
	-o-transform: rotateY(#{$intRotateY + "deg"})
	-webkit-transform: rotateY(#{$intRotateY + "deg"})
	transform: rotateY(#{$intRotateY + "deg"})

=setRotateZ($intRotateZ)
	-ms-transform: rotateZ(#{$intRotateZ + "deg"})
	-moz-transform: rotateZ(#{$intRotateZ + "deg"})
	-o-transform: rotateZ(#{$intRotateZ + "deg"})
	-webkit-transform: rotateZ(#{$intRotateZ + "deg"})
	transform: rotateZ(#{$intRotateZ + "deg"})

=setSlideTransform($intRotateY, $intTranslateX, $intTranslateZ, $intScaleX, $intScaleY, $intScaleZ)
	-ms-transform: rotateY(#{$intRotateY + "deg"}) translateX($intTranslateX) translateZ($intTranslateZ) scale3d($intScaleX, $intScaleY, $intScaleZ)
	-moz-transform: rotateY(#{$intRotateY + "deg"}) translateX($intTranslateX) translateZ($intTranslateZ) scale3d($intScaleX, $intScaleY, $intScaleZ)
	-o-transform: rotateY(#{$intRotateY + "deg"}) translateX($intTranslateX) translateZ($intTranslateZ) scale3d($intScaleX, $intScaleY, $intScaleZ)
	-webkit-transform: rotateY(#{$intRotateY + "deg"}) translateX($intTranslateX) translateZ($intTranslateZ) scale3d($intScaleX, $intScaleY, $intScaleZ)
	transform: rotateY(#{$intRotateY + "deg"}) translateX($intTranslateX) translateZ($intTranslateZ) scale3d($intScaleX, $intScaleY, $intScaleZ)

=setSkew($intSkewX, $intSkewY)
	-ms-transform: skew($intSkewX, $intSkewY)
	-moz-transform: skew($intSkewX, $intSkewY)
	-o-transform: skew($intSkewX, $intSkewY)
	-webkit-transform: skew($intSkewX, $intSkewY)
	transform: skew($intSkewX, $intSkewY)

=setScale3D($intScaleX, $intScaleY, $intScaleZ)
	-ms-transform: scale3d($intScaleX, $intScaleY, $intScaleZ)
	-moz-transform: scale3d($intScaleX, $intScaleY, $intScaleZ)
	-o-transform: scale3d($intScaleX, $intScaleY, $intScaleZ)
	-webkit-transform: scale3d($intScaleX, $intScaleY, $intScaleZ)
	transform: scale3d($intScaleX, $intScaleY, $intScaleZ)

=setTransformStyle($stringTransformStyle)
	-ms-transform-style: $stringTransformStyle
	-moz-transform-style: $stringTransformStyle
	-o-transform-style: $stringTransformStyle
	-webkit-transform-style: $stringTransformStyle
	transform-style: $stringTransformStyle

// TRANSFORM - END


// ANIMATION - START

=useAnimation($stringAnimationName, $intAnimationDuration, $intAnimationDelay, $intAnimationIteration)
	-ms-animation: $stringAnimationName #{$intAnimationDuration + "s"} #{$intAnimationDelay + "s"} $intAnimationIteration forwards
	-moz-animation: $stringAnimationName #{$intAnimationDuration + "s"} #{$intAnimationDelay + "s"} $intAnimationIteration forwards
	-o-animation: $stringAnimationName #{$intAnimationDuration + "s"} #{$intAnimationDelay + "s"} $intAnimationIteration forwards
	-webkit-animation: $stringAnimationName #{$intAnimationDuration + "s"} #{$intAnimationDelay + "s"} $intAnimationIteration forwards
	animation: $stringAnimationName #{$intAnimationDuration + "s"} #{$intAnimationDelay + "s"} $intAnimationIteration forwards

=setAnimationTimingFunction($stringAnimationTimingFunction)
	-ms-animation-timing-function: $stringAnimationTimingFunction
	-moz-animation-timing-function: $stringAnimationTimingFunction
	-o-animation-timing-function: $stringAnimationTimingFunction
	-webkit-animation-timing-function: $stringAnimationTimingFunction
	animation-timing-function: $stringAnimationTimingFunction

=generateKeyFrames($stringAnimationName)
	@-webkit-keyframes #{$stringAnimationName}
		@content
	@-moz-keyframes #{$stringAnimationName}
		@content
	@-ms-keyframes #{$stringAnimationName}
		@content
	@keyframes #{$stringAnimationName}
		@content

// ANIMATION - END


// INPUT - START

=generateInputPlaceholder($stringColor)
	::-webkit-input-placeholder
		color: $stringColor
	:-ms-input-placeholder
		color: $stringColor
	::placeholder
		color: $stringColor

// INPUT - END


// SCROLLBAR - START

=setScrollbarBackgroundColor($stringColor)
	::-ms-scrollbar
		background-color: $stringColor
	::-moz-scrollbar
		background-color: $stringColor
	::-o-scrollbar
		background-color: $stringColor
	::-webkit-scrollbar
		background-color: $stringColor

=setScrollbarThumbColor($stringColor)
	::-ms-scrollbar-thumb
		background-color: $stringColor
	::-moz-scrollbar-thumb
		background-color: $stringColor
	::-o-scrollbar-thumb
		background-color: $stringColor
	::-webkit-scrollbar-thumb
		background-color: $stringColor

=setScrolllbarWidthHeight($stringWidth, $stringHeight)
	::-ms-scrollbar
		height: $stringHeight
		width: $stringWidth
	::-moz-scrollbar
		height: $stringHeight
		width: $stringWidth
	::-o-scrollbar
		height: $stringHeight
		width: $stringWidth
	::-webkit-scrollbar
		height: $stringHeight
		width: $stringWidth

// SCROLLBAR - END