/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// GENERAL - START

/* FORM - START */

form
	margin-bottom: $SPACE_HUGE
form.FormInsertConversation
	div.DivConversationGeneral
		div.DivConversationContent
			textarea
				border-bottom-left-radius: 0px
				border-bottom-right-radius: 0px
				resize: none
				margin-bottom: 0px
				padding-bottom: 0px

div.DivForm, form div.DivForm fieldset
	input, textarea
		+setBoxSizing(border-box)
	input
		height: $HEIGHT_INPUT_SINGLELINE
	input, select, textarea, textarea.TextareaDisable
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		width: 100%
		min-width: $WIDTH_INPUT_SQUARE
		padding-top: $PADDING_INPUT_EDGE
		padding-bottom: $PADDING_INPUT_EDGE
		padding-right: $PADDING_INPUT_SIDE
		padding-left: $PADDING_INPUT_SIDE
		font-size: $FONT_INPUT_GENERAL
		border-style: none
		+setBorderRadius($RADIUS_INPUT_GENERAL)
	input.InputToken
		min-width: $WIDTH_INPUT_SQUARE
		width: $WIDTH_INPUT_SQUARE
		text-align: center
	input::-webkit-outer-spin-button, input::-webkit-inner-spin-button
		+setAppearance(none)
		margin: 0px
	input[type=number]
		+setAppearance(textfield)
	mat-slider
		width: 100%
		height: 200px
	select
		min-height: $HEIGHT_SELECT_SINGLELINE
		background-repeat: no-repeat
		background-position: right
		+setAppearance(none)
	textarea
		height: $HEIGHT_TEXTAREA_GENERAL
		resize: vertical
	textarea:disabled
		height: $HEIGHT_TEXTAREA_READONLY * 2
	textarea.TextareaDescriptionHeader
		height: $HEIGHT_TEXTAREA_HEADER !important
		font-size: $FONT_INPUT_TEXTAREA_HEADERHEADLINE
		margin: 0px
	textarea.TextareaDisable
		height: $HEIGHT_TEXTAREA_READONLY
		resize: none
		padding: 0
		+setBorderRadius(0px)
	textarea.TextareaDefault
		resize: none
		height: inherit
	textarea.TextareaDescriptionDla
		height: 113px
	div.DivRadioButtonContainerVertical
		flex-direction: column
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical, div.DivCheckBoxContainer
		label.LabelRadioButtonContainer, label.LabelCheckboxContainer
			line-height: 1.1
			display: flex !important
			align-items: center
			flex-direction: row
			gap: 10px
			input[type=radio], input[type=checkbox]
				border: 0.25em solid #787878
				-webkit-appearance: none
				appearance: none
				background-color: transparent
				margin: 0px
				font: inherit
				color: black
				transform: translateY(-0.075em)
				display: grid
				place-content: center
				padding: 5px 10px !important
				cursor: pointer
				&::before
					content: ""
					transform: scale(0)
					transition: 100ms transform ease-in-out
					background-color: transparent
				&:disabled
					&::before
						box-shadow: inset 1em 1em #787878
				&:checked
					&::before
						transform: scale(1)
		label.LabelRadioButtonContainer
			margin-left: 10px !important
			input[type=radio]
				width: 38px !important
				height: 38px !important
				border-radius: 50%
				&::before
					width: 22px
					height: 22px
					border-radius: 50%
					box-shadow: inset 23px 23px #F26F21
		label.LabelCheckboxContainer
			input[type=checkbox]
				width: 30px !important
				height: 30px !important
				min-height: 0px
				min-width: 0px
				border-radius: 8px
				&::before
					content: ""
					width: 15px
					height: 15px
					border-radius: 4px
					box-shadow: inset 15px 15px #F26F21
	div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
		display: flex
		div.DivRadioButton
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_RADIO
			height: $SIZE_INPUT_RADIO
			padding: $SPACE_TINY
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius(50%)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=radio]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius(50%)
			input[type=radio]:checked + label
				width: 100%
				height: 100%
		div.DivRadioButton + label
			margin-right: $SPACE_MEDIUM
		div.DivRadioButtonMerge
			display: flex
			align-items: center
		div.DivRadioButtonInline
			box-sizing: border-box
			width: 160px
			margin: 20px 10px
			div.DivRadioButton
				margin: auto
			label.Margin0
				margin-left: 0
				margin-right: 0
				text-align: center

	select.SelectMobilePhonePrefix, select.SelectMedium
		min-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		width: $WIDTH_INPUT_MOBILEPHONE_PREFIX
		text-align: left
		margin-right: $SPACE_LITTLE
	select.InputLicensePlatePrefix, select.selectShort
		min-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		max-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX * 2
		text-align: left
		margin-right: $SPACE_TINY
	select.inputEstimateClaim
		width: $WIDTH_INPUT_ESTIMATE_VALUE
		text-align: left
		margin-right: $SPACE_TINY
	input.InputLicensePlateInfix
		min-width: $WIDTH_INPUT_LICENSEPLATE_INFIX
		max-width: auto
		text-align: center
		margin-right: $SPACE_TINY
		margin-left: $SPACE_TINY
	input.InputLicensePlateSuffix, select.SelectLong
		box-sizing: border-box
		min-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX
		max-width: $WIDTH_INPUT_LICENSEPLATE_PREFIX * 3
		min-width: $WIDTH_INPUT_SQUARE
		text-align: center
		margin-left: $SPACE_TINY
	fieldset
		mat-form-field
			width: 100%
			margin: 0px
			.mat-form-field-infix
				width: $WIDTH_MATFORMFIELD_INFIX
	div.DivCheckBoxContainer
		display: flex
		align-items: center
		div.DivCheckBox
			margin-right: $SPACE_LITTLE
			width: $SIZE_INPUT_CHECKBOX
			height: $SIZE_INPUT_CHECKBOX
			padding: $PADDING_INPUT_CHECKBOX
			border-style: solid
			border-width: $WIDTH_BORDER_INPUTTHICK
			+setBorderRadius($RADIUS_INPUT_CHECKBOXOUTER)
			display: flex
			align-items: center
			align-content: center
			justify-content: center
			input[type=checkbox]
				display: none
			label
				margin: 0px
				width: 0px
				height: 0px
				+setBorderRadius($RADIUS_INPUT_CHECKBOXINNER)
			input[type=checkbox]:checked + label
				width: 100%
				height: 100%
		div.DivCheckBox + label
			margin-right: $SPACE_MEDIUM
			margin-top: $SPACE_NARROW
			margin-bottom: $SPACE_NARROW
	mat-form-field
		margin: 0px
		padding: 0px
		min-width: auto
	div.DivTimer
		display: flex
		justify-content: flex-start
		select.SelectTimePrefix
			width: $SIZE_LAYOUT_PROFILEIMAGE
		span.SpanTimeInfix
			margin-top: $SPACE_SMALL
			margin-left: $SPACE_LITTLE
			margin-right: $SPACE_LITTLE
		select.SelectTimeSuffix
			width: $SIZE_LAYOUT_PROFILEIMAGE
	input.mat-input-element
			margin: 0px
			padding: 0px
			min-width: auto

div.DivMainAsideSearch, div.DivTransparantContainer
	div.DivDynamicContainer
		form
			width: auto
		input[type=button].ButtonSubmit
			width: $WIDTH_LINK_BUTTON_CONVERSATION
div.DivMainAsideSearch
	form
		margin-right: $SPACE_SMALL
		width: $WIDTH_LAYOUT_SIGNIN
		flex-shrink: 0
	div.DivMainTable
		width: 100%
		margin-left: $SPACE_SMALL
		form
			width: 100%
		div.DivTableContainer
			margin-left: $SPACE_ZERO
			height: auto
			table.TableListDetailsAttachment
				select
					min-height: $HEIGHT_SELECT_SINGLELINE
					background-repeat: no-repeat
					background-position: right
					+setAppearance(none)
					margin-bottom: $SPACE_NARROW
					margin-top: $SPACE_NARROW
					width: 100%
					min-width: $WIDTH_INPUT_SQUARE
					padding-top: $PADDING_INPUT_EDGE
					padding-bottom: $PADDING_INPUT_EDGE
					padding-right: $PADDING_INPUT_SIDE
					padding-left: $PADDING_INPUT_SIDE
					font-size: $FONT_INPUT_GENERAL
					border-style: none
					+setBorderRadius($RADIUS_INPUT_GENERAL)
				select#modelAnomalyGeneralStatus
					width: inherit

form.FormRadioButtonCenterContainer, form.FormRadioButtonDefaultContainer
	label.LabelRadioButtonVerticalContainer
		font-size: 0.9rem
		flex-direction: column
		margin-right: 10px
		input[type=radio]
			border: 0.3em solid #FFF
	label.LabelRadioButtonHorizontalContainer
		font-size: 1em !important
		flex-direction: row
		gap: 15px
		margin-right: 20px
		input[type=radio]
			border: 0.25em solid #787878
	label.LabelRadioButtonVerticalContainer, label.LabelRadioButtonHorizontalContainer
		line-height: 1.1
		display: flex !important
		align-items: center
		input[type=radio]
			-webkit-appearance: none
			appearance: none
			background-color: transparent
			margin: 0 0 5px 0
			font: inherit
			color: black
			width: 2.45em
			height: 2.45em
			border-radius: 50%
			transform: translateY(-0.075em)
			display: grid
			place-content: center
			&::before
				content: ""
				width: 1.35em
				height: 1.35em
				border-radius: 50%
				transform: scale(0)
				transition: 100ms transform ease-in-out
				box-shadow: inset 1em 1em #F7931E
				background-color: transparent
			&:checked
				&::before
					transform: scale(1)
		&:last-of-type
			margin: 0

div.DivContainerForUpload
	input[type=file]
		display: none
	input[type=button]
		background-repeat: no-repeat

div.DivForm
	div.DivForm3ColumnOwnRetention
		input[type=text].inputOwnRetentionHeadOffice
			width: 120px
			margin-right: 10px
		input[type=text].inputOwnRetentionAmountHeadOffice
			width: 240px
			margin-right: 10px
			margin-left: 10px

/* FORM - END */

/* THIRD PARTY - START */

div.DivForm, form div.DivForm fieldset
	.mat-form-field-appearance-fill, .mat-form-field-wrapper, .mat-form-field-flex, .mat-mdc-text-field-wrapper
		+setBorderRadius($RADIUS_INPUT_GENERAL)
		padding-right: $RADIUS_LAYOUT_CARD
	.mat-form-field-underline
		display: none
	.mat-form-field-wrapper
		padding-bottom: 0px
	.mat-form-field-appearance-fill
		width: 100%
	input.mat-date-range-input-inner, input.mat-datepicker-input
		height: 100%
		font: inherit
		border: none
		outline: none
		padding: 0
		margin: 0
		vertical-align: bottom
		text-align: inherit
		+setAppearance(none)
		width: 100%
	.mat-form-field
		margin-bottom: $SPACE_NARROW
		margin-top: $SPACE_NARROW
		input
			margin: 0px
			padding: 0px
			min-width: auto
	.mat-mdc-icon-button
		width: 40px
		height: 40px
		display: inline-flex
		align-items: center
		justify-content: center
		padding: 0px

div.DivForm
	fieldset
		width: 100%
		padding: $SPACE_LITTLE
		margin: $SPACE_LITTLE 0px
		border: none
		border-radius: $SPACE_LITTLE
	.ng-select
		margin-right: $SPACE_LITTLE
		width: 100%
	.ng-dropdown-panel
		width: 100%
		padding-left: $SPACE_LITTLE
	.ng-option
		margin-top: $SPACE_TINY
	.ng-select.custom .ng-clear-wrapper .ng-clear
		font-size: 0px

agm-map
	margin: 200px
	background-color: red
	height: 500px
	width: 100%

mat-form-field
	margin: 0px
	padding: 0px
	min-width: auto

input.mat-input-element
		margin: 0px
		padding: 0px
		min-width: auto

/* THIRD PARTY - END */

// GENERAL - END


// NAVIGATION - START

// NAVIGATION - END


// SIGN IN - START

// SIGN IN - END


// TABLE - START

div.DivTableContainer
	div.DivTableControl, div.DivConversationStatus
		select
			padding-top: $PADDING_INPUT_EDGE
			padding-bottom: $PADDING_INPUT_EDGE
			padding-right: $PADDING_INPUT_SIDE
			padding-left: $PADDING_INPUT_SIDE
			border-style: none
			background-repeat: no-repeat
			background-position: right
			+setAppearance(none)
	div.DivTableControl
		select
			margin-bottom: $SPACE_NARROW
			margin-top: $SPACE_NARROW
			width: 100%
			min-width: $WIDTH_INPUT_SINGLELINE
			min-height: $HEIGHT_SELECT_SINGLELINE
			font-size: $FONT_INPUT_GENERAL
			+setBorderRadius($RADIUS_INPUT_GENERAL)
	div.DivConversationStatus
		select
			font-size: $FONT_SELECT_SEVERITY
			width: $WIDTH_LABEL_CONVERSATIONSTATUS
			+setBorderRadius($RADIUS_TICKET_STATUS)
	div.DivConversationContent
		textarea
			+setBoxSizing(border-box)
			width: 100%
			min-width: $WIDTH_INPUT_SQUARE
			padding: $PADDING_INPUT_SIDE
			font-size: $FONT_INPUT_GENERAL
			border-style: none
			+setBorderRadius($RADIUS_INPUT_GENERAL)

// TABLE - END


// .FORM SURVEY SATISFACTION START
div.DivFormContainerForSatisfactionSurvey
	div.DivForm
		div.DivFormHorizontalContainer
			div.DivForm2Column
				div.DivContainerHorizontalRadioButton
					display: flex
					padding: 20px 0px 20px 0px
					div.DivRadioButtonContainer
						display: flex
						flex-direction: column
						align-items: center
						width: 100%
				fieldset
					textarea
						height: 115px
// .FORM SURVEY SATISFACTION END


// .TRACKING CLAIM START
div.DivButtonTracking
	input[type=button]:nth-child(2)
		margin-top: 20px

div.DivTrackingStepperContainer
	ul
		li.LiStepperHorizontal, li.LiStepperHorizontalForChildNoLine, li.LiStepperHorizontalForParentNoLine, li.LiStepperHorizontalForChildNoLineDisable
			div.DivTitleStepper
				div.DivSubTitleStepper, div.DivSubTitleStepperForSurvey
					input[type=button]
						width: 200px
				div.DivSubTitleStepperForSurvey
					input[type=button]
						margin: 0px
						width: 220px
						margin-left: -50px
				div.DivSubTitleStepperForSurveyForInsert
					margin-left: 220px !important
					width: 220px
					input[type=button]
						margin: 0px
				div.DivContainerSteperContent
					div.DivContainerForImage
						div.DivWrapperImg
							input[type=button]
								width: 15px
								height: 15px
								flex-direction: column
								margin-bottom: 0px
								margin-left: 140px
							input[type=button].ButtonRemoveImg
								width: 20px
								height: 30px
								align-content: center
								margin-left: 20px
								margin-top: 7px
					input[type=button].ButtonUpload
						width: 100px
					div.DivContainerForUpload
						input[type=file]
							display: none
			div.DivContainerDateTimePicker
				input[type=button]
					width: 100px
					margin-left: 20px
// .TRACKING CLAIM END


/* SURVEY ONLINE - START */

div#divNotificationOnlineSurvey
	visibility: hidden
	div.DivNotificationOnlineSurveyCard
		div.DivNotificationOnlineSurveyContent
			input[type=button]#buttonNextSurveyOnline
				margin-top: 30px
		input[type=button]#buttonCloseNotificationSurveyOnlineModal
			position: absolute
			right: 15px
			top: 15px
			border: none
			background-color: transparent
			font-size: 24px
			font-weight: 500

/* SURVEY ONLINE - END */